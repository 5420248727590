<div class="mat-elevation-z1 p-12 mx-8" fxLayout="column">
  <h3 class="py-8 pl-8 font-weight-bold">
    <a
      [href]="'https://www.tiktok.com/@' + account?.username + '/'"
      rel="noreferrer noopener"
      target="_blank"
    >
      @{{ account?.username }}
    </a>
  </h3>
  <div class="attributes" fxLayout="row" fxLayoutAlign="space-between">
    <div class="px-12" fxLayout="column">
      <div class="p-8">フォロワー数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.follower | number) || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">フォロー数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.followee | number) || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">インプレッション数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.average_impression | number: '1.1') || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">リーチ数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.average_reach | number: '1.1') || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">いいね数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.average_like | number: '1.1') || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">コメント数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.average_comment | number: '1.1') || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">保存数</div>
      <div fxLayoutAlign="start">
        {{ (performance?.average_saved | number: '1.1') || '-' }}
      </div>
    </div>
    <div class="px-12" fxLayout="column">
      <div class="p-8">エンゲージメント率</div>
      <div fxLayoutAlign="start">
        {{ (engagement | percent: '1.1') || '-' }}
      </div>
    </div>
  </div>
</div>
