import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { Medium, MediumService, RewardType } from '@cuepid/core';

@Component({
  selector: 'app-input-campaign-reward',
  templateUrl: './input-campaign-reward.component.html',
  styleUrls: ['./input-campaign-reward.component.scss']
})
export class InputCampaignRewardComponent implements OnChanges
{
  @Input() readonly = false;

  @Input() medium: Medium | null = null;

  formState = false;

  readonly instagram = Medium.INSTAGRAM;
  readonly tiktok = Medium.TIKTOK;
  readonly hints = {
    reward: '⚪︎フォロワー数\n　応募時点のフォロワー数 × 指定した金額\n　が各インフルエンサーへの報酬金額となります。\n\n⚪︎金額指定\n　ご入力いただいた金額が、そのまま各インフルエンサーへの報酬金額となります。',
  }

  form: FormGroup;
  types: RewardType[] = [];

  constructor(private fb: FormBuilder, private mediumService: MediumService) {
    this.form = fb.group({
      enabled: [false, [Validators.required]],
      type: [1, [Validators.required]],
      amount: ['', [Validators.required, CustomValidators.digits]],
    });
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.medium) {
      return;
    }

    // 平均いいね数による報酬計算は廃止
    this.types = this.mediumService.getRewardTypes(this.medium?.id)?.filter(type => type.code !== 2) ?? [];
    this.form.patchValue({type: this.types[0].code});
  }

  changeFormState(enable: boolean) {
    if (enable) {
      this.form.enable();
      this.form.get('enabled')?.patchValue(true);
      return;
    }

    this.form.get('enabled')?.patchValue(false);
    this.form.disable();
  }

  restoreData(data: {[key: string]: any}): void
  {
    if (data['type'] && data['amount']) {
      data['enabled'] = true;
    }

    this.form.patchValue(data);

    if (this.form.get('enabled')?.value) {
      this.form.enable();
      this.formState = true;
      return;
    }

    this.form.disable();
    this.formState = false;
  }
}
