import { Component, Input } from '@angular/core';
import { TiktokAccount, TiktokPerformance } from '@cuepid/core';

@Component({
  selector: 'app-tiktok-account-basic-info',
  templateUrl: './tiktok-account-basic-info.component.html',
  styleUrls: ['./tiktok-account-basic-info.component.scss'],
})
export class TiktokAccountBasicInfoComponent {

  @Input() account?: TiktokAccount;

  get performance(): TiktokPerformance | undefined | null {
    return this.account?.latest_performance;
  }

  get engagement(): number {
    if (!this.performance || !this.performance.average_impression) {
      return 0;
    }

    return (this.performance.average_like + this.performance.average_comment + this.performance.average_saved)
      / this.performance.average_impression
  }
}
