import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Campaign, Medium, MediumService } from '@cuepid/core';
import { ConfirmRemoveHashtagComponent } from '@cuepid/enterprise/shared/components/campaign/confirm-remove-hashtag/confirm-remove-hashtag.component';
import {
  SelectTemplateComponent,
  SelectTemplateComponentData,
} from '@cuepid/enterprise/shared/components/campaign/select-template/select-template.component';
import { format } from 'date-fns';
import { CustomValidators } from 'ngx-custom-validators';
import { filter, tap } from 'rxjs/operators';

type DefaultTemplateType =
  | 'otherNotesForPostingProcedure'
  | 'flowAfterHiring'
  | 'note';

@Component({
  selector: 'app-input-campaign-requirement',
  templateUrl: './input-campaign-requirement.component.html',
  styleUrls: ['./input-campaign-requirement.component.scss'],
  animations: [
    trigger('switchPeriod', [
      state('*', style({ opacity: 1, display: 'block' })),
      state('void', style({ opacity: 0, display: 'none' })),
      transition('* => void', [animate('0.3s', style({ opacity: 0 }))]),
      transition('void => *', [animate('0.3s', style({ opacity: 1 }))]),
    ]),
    trigger('showTemplate', [
      state('*', style({ height: '*', opacity: 1, overflow: 'hidden' })),
      state('void', style({ height: 0, opacity: 0 })),
      transition('* => void', [animate('0.3s', style({ height: 0 }))]),
      transition('void => *', [
        animate('0.3s', style({ height: '*', opacity: 1 })),
      ]),
    ]),
  ],
})
export class InputCampaignRequirementComponent implements OnChanges {
  @Input() readonly = false;

  @Input() type?: Campaign.Type;

  form: FormGroup;
  showMandatoryTemplate = false;
  showNoteTemplate = false;

  readonly instagram = Medium.INSTAGRAM;
  readonly tiktok = Medium.TIKTOK;
  readonly eventType = Campaign.Type.EVENT;
  readonly toTerm = Campaign.PostType.TO_TERM;
  readonly toDay = Campaign.PostType.TO_DAY;

  readonly hints: { [key: string]: string } = {
    minFollower:
      'インフルエンサーに求める最低フォロワー数をご指定ください。\nサービスの特徴上最低100以上の設定が必須となります。',
    preferredDate:
      '⚪︎期間指定\n　指定期間内への投稿をご依頼する場合\n⚪︎日数指定\n　採用から投稿完了までの希望日をご入力ください。\n　余裕をもったスケジュールをご指定ください。\n※【推奨】商品到着から10日前後以内',
    secondaryUse:
      'インフルエンサーが投稿された素材を、InstagramアカウントやWEBサイトに掲載する可能性がある場合など、2次利用をご希望される方は、チェックを入れてください。',
    hashTags:
      '投稿されたいハッシュタグを追加ください。\n※「PR」「sponsored」などPR投稿である旨を指すハッシュタグの記載は原則必須となります。',
    postingProcedure:
      '各項目にチェックいただき、不足があれば「その他記載事項」へご記入ください。',
    flowAfterHiring: '採用された後の流れをテンプレートを参考にご記入ください。',
    note: '上記にて設定・指定したもの以外を中心に、テンプレートを参考にご記入ください。',
  };

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private mediumService: MediumService
  ) {
    const postType = fb.control(null);

    this.form = fb.group({
      medium_id: [this.instagram.id, [Validators.required]],
      show_ones_face: [true, [Validators.required]],
      tagging_into_post: [true, [Validators.required]],
      include_account_name_in_post_caption: [true, [Validators.required]],
      other_notes_for_posting_procedure: ['', []],
      flow_after_hiring: ['', [Validators.required]],
      note: ['', [Validators.required]],
      secondary_use: [false, [Validators.required]],

      beginning: [
        '',
        [
          CustomValidators.date,
          (control: AbstractControl) =>
            this.setRequiredTermValidator(control, postType),
        ],
      ],
      deadline: [
        '',
        [
          CustomValidators.date,
          (control: AbstractControl) =>
            this.setRequiredTermValidator(control, postType),
        ],
      ],
      grace_days: [
        '',
        [
          CustomValidators.digits,
          Validators.max(120),
          (control: AbstractControl) =>
            this.setRequiredDaysValidator(control, postType),
        ],
      ],

      instagram: fb.group({
        min_follower: [
          100,
          [Validators.required, Validators.min(100), CustomValidators.digits],
        ],
        hashtag: fb.array([]),
        tags_in_image: fb.array([]),
        tags_in_caption: fb.array([]),
        required_partnership_tags: fb.control(true),
        partnership_tags: fb.array([]),
      }),

      // フォーム表示項目制御系
      postType,
    });

    postType.valueChanges.subscribe((type) => {
      const { beginning, deadline, grace_days: graceDays } = this.form.controls;
      if (type === this.toTerm.code) {
        graceDays.reset();
      } else if (type === this.toDay.code) {
        beginning.reset();
        deadline.reset();
      } else {
        graceDays.reset();
        beginning.reset();
        deadline.reset();
      }
    });

    // デフォルトのタグを設定
    this.addTagsInput('PR');
    this.addTagsInput('', 'instagram.tags_in_image');
    this.addTagsInput('', 'instagram.tags_in_caption');
    this.addTagsInput('', 'instagram.partnership_tags');

    (
      this.form.get('tagging_into_post') as AbstractControl
    ).valueChanges.subscribe((value) => {
      if (
        value &&
        this.getTagsInputs('instagram.tags_in_image').controls.length === 0
      ) {
        this.addTagsInput('', 'instagram.tags_in_image');
      }
    });

    (
      this.form.get('include_account_name_in_post_caption') as AbstractControl
    ).valueChanges.subscribe((value) => {
      if (
        value &&
        this.getTagsInputs('instagram.tags_in_caption').controls.length === 0
      ) {
        this.addTagsInput('', 'instagram.tags_in_caption');
      }
    });

    this.form
      .get('instagram.required_partnership_tags')
      ?.valueChanges.pipe(
        filter(
          (value) =>
            !!value &&
            this.getTagsInputControls('instagram.partnership_tags').length === 0
        )
      )
      .subscribe(() => this.addTagsInput('', 'instagram.partnership_tags'));
  }

  get postType() {
    return this.form.get('postType')?.value;
  }

  get medium(): Medium | null {
    const mediumId = this.form.get('medium_id')?.value;
    return this.mediumService.getMedium(mediumId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.type) {
      return;
    }

    const { postType } = this.form.controls;
    if (this.type === Campaign.Type.SAMPLING) {
      postType.setValue(this.toTerm.code);
    } else {
      postType.setValue(null);
    }
  }

  setFormattedDate(formControlName: string, value: unknown) {
    this.form.controls[formControlName].setValue(
      format(value as Date, 'yyyy-MM-dd')
    );
  }

  getTagsInputs(formPath: string = 'instagram.hashtag'): FormArray {
    return this.form.get(formPath) as FormArray;
  }

  getTagsInputControls(formPath: string = 'instagram.hashtag'): FormControl[] {
    const controls = this.form.get(formPath) as FormArray;
    return (controls?.controls as FormControl[]) ?? [];
  }

  addTagsInput(
    value: string = '',
    formPath: string = 'instagram.hashtag'
  ): void {
    const control = this.fb.control(value, [Validators.required]);
    switch (formPath) {
      case 'instagram.hashtag':
        control.addValidators(Validators.pattern('^(?!#).*$'));
        break;
      case 'instagram.tags_in_image':
      case 'instagram.tags_in_caption':
      case 'instagram.partnership_tags':
        control.addValidators(Validators.pattern('^(?!@).*$'));
    }
    this.getTagsInputs(formPath).push(control);
  }

  canRemoveTag(formPath: string): boolean {
    const controls = this.getTagsInputs(formPath) as FormArray;
    switch (formPath) {
      case 'instagram.tags_in_image':
        return (
          !this.form.get('tagging_into_post')?.value ||
          controls.controls.length > 1
        );
      case 'instagram.tags_in_caption':
        return (
          !this.form.get('include_account_name_in_post_caption')?.value ||
          controls.controls.length > 1
        );
      case 'instagram.partnership_tags':
        return (
          !this.form.get('instagram.required_partnership_tags')?.value ||
          controls.controls.length > 1
        );
    }
    return true;
  }

  removeTag(index: number, formPath: string = 'instagram.hashtag') {
    const control = this.getTagsInputs(formPath).at(index);
    if (!control.value) {
      this.getTagsInputs(formPath).removeAt(index);
      return;
    }

    const type = formPath === 'instagram.hashtag' ? 'hashtag' : 'tag';
    const dialogRef = this.dialog.open(ConfirmRemoveHashtagComponent, {
      data: { tag: control.value, type },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.getTagsInputs(formPath).removeAt(index);
      }
    });
  }

  openTemplateList(
    control: AbstractControl,
    targetElement: HTMLElement,
    type: DefaultTemplateType
  ) {
    const data: SelectTemplateComponentData = {};
    switch (type) {
      case 'otherNotesForPostingProcedure':
        data.defaultTemplateBody =
          this.otherNotesForPostingProcedureDefaultTemplate();
        break;
      case 'flowAfterHiring':
        data.defaultTemplateBody = this.flowAfterHiringDefaultTemplate();
        break;
      case 'note':
        data.defaultTemplateBody = this.noteDefaultTemplate();
        break;
    }

    this.dialog
      .open(SelectTemplateComponent, {
        data,
        width: '50vw',
      })
      .afterClosed()
      .pipe(
        filter((value) => !!value),
        tap(() => targetElement.focus()),
        tap((value) => control.patchValue(value))
      )
      .subscribe();
  }

  restoreData(data: { [key: string]: any }): void {
    setTimeout(() => {
      this.form.patchValue(data);
      // Apply hashtags
      const fg = this.form.get('instagram') as FormGroup;
      fg.setControl('hashtag', this.fb.array([]));
      fg.setControl('tags_in_image', this.fb.array([]));
      fg.setControl('tags_in_caption', this.fb.array([]));
      fg.setControl('partnership_tags', this.fb.array([]));
      if (data['instagram']) {
        if (data['instagram']['hashtag']) {
          for (const hashtag of Array.from<string>(
            data['instagram']['hashtag']
          )) {
            this.addTagsInput(hashtag);
          }
        }
        if (data['instagram']['tags_in_image']) {
          for (const hashtag of Array.from<string>(
            data['instagram']['tags_in_image']
          )) {
            this.addTagsInput(hashtag, 'instagram.tags_in_image');
          }
        }
        if (data['instagram']['tags_in_caption']) {
          for (const hashtag of Array.from<string>(
            data['instagram']['tags_in_caption']
          )) {
            this.addTagsInput(hashtag, 'instagram.tags_in_caption');
          }
        }
        if (data['instagram']['partnership_tags']) {
          for (const hashtag of Array.from<string>(
            data['instagram']['partnership_tags']
          )) {
            this.addTagsInput(hashtag, 'instagram.partnership_tags');
          }
        }
        if (data['instagram']['required_partnership_tags']) {
          this.form
            .get('instagram.required_partnership_tags')
            ?.patchValue(!!data['instagram']['required_partnership_tags']);
        }
      }

      if (typeof data['show_ones_face'] !== 'boolean') {
        this.form.get('show_ones_face')?.patchValue(!!data['show_ones_face']);
      }
      if (typeof data['tagging_into_post'] !== 'boolean') {
        this.form
          .get('tagging_into_post')
          ?.patchValue(!!data['tagging_into_post']);
      }
      if (typeof data['include_account_name_in_post_caption'] !== 'boolean') {
        this.form
          .get('include_account_name_in_post_caption')
          ?.patchValue(!!data['include_account_name_in_post_caption']);
      }
    });
  }

  private setRequiredTermValidator(
    dateLike: AbstractControl,
    postType: AbstractControl
  ) {
    if (postType.value === 0 && !dateLike.value) {
      return { requiredTerm: true };
    }
    return null;
  }

  private setRequiredDaysValidator(
    control: AbstractControl,
    postType: AbstractControl
  ) {
    if (postType.value === 1 && !control.value) {
      return { requiredDays: true };
    }
    return null;
  }

  private otherNotesForPostingProcedureDefaultTemplate(): string {
    return (
      '' +
      `※以下は、必要に応じて修正の上ご利用ください※
・ストーリーズやリール投稿、また動画フォーマットの投稿指定がある場合はご指定下さい。
　→ 条件が増えると応募いただける方の母数に影響がでる可能性がございます。`
    );
  }

  private flowAfterHiringDefaultTemplate(): string {
    return (
      '' +
      `▼ 手順
①当選者へのみ「当選通知」が届きます。
②当選後、メッセージにて住所の確認などを行います。
　案件期間中は気をつけてメッセージをご確認くださいませ。
③指定の期日内に商品が発送されます。
　もし商品が届かないなどございましたら、メッセージよりお問い合わせください。
④商品受け取り後、予め定められた期日内に、
   「商品紹介・特徴」「商品誕生の背景や想い」「投稿に関しての備考・注意事項」をよく読み、
　素敵な投稿をお願いします。
⑤投稿が完了しましたら「完了報告」をお願いします。
　弊社で投稿内容を確認させていただきます。
⑥問題がなければこちらで以上となります。

※原則キャンペーンに当選された後は、キャンセル受け付けは出来かねます。`
    );
  }

  private noteDefaultTemplate(): string {
    return (
      '' +
      `▼ 注意事項
・商品やロゴが見える角度での撮影をお願いします。
・競合商品との同一投稿はお控えください。
・投稿後のお写真の削除はお控えください。
・原則キャンペーンに当選された後は、キャンセル受け付けは出来かねます。
・公序良俗、法令順守に基づいた投稿を心がけてください。
・上記から逸脱した投稿が見られる場合は、完了報告時に修正のご依頼を
　させていただきますが予めご了承ください。

ーーCuepid事務局よりーーー
企業さまとのお問い合わせで解決しない問題や、連絡が取れなくなったなどのトラブルがございましたら、
以下メールアドレスへご連絡くださいませ。

株式会社アイドマ・ホールディングス
crapro_tool@aidma-hd.jp
ーーーーーーーーーーーーー`
    );
  }
}
