import { Component, Inject, Input, OnChanges } from '@angular/core';
import { TiktokPost, WINDOW } from '@cuepid/core';

@Component({
  selector: 'app-tiktok-post-overview',
  templateUrl: './tiktok-post-overview.component.html',
  styleUrls: ['./tiktok-post-overview.component.scss'],
})
export class TiktokPostOverviewComponent implements OnChanges {
  @Input() post?: TiktokPost;

  constructor(@Inject(WINDOW) private readonly window: Window) {}

  ngOnChanges(): void {
    if (!this.post || !this.post.media_url) {
      return;
    }

    /**
     * Instagram oEmbedを使う時、embed内のHTMLが読み込むはずのスクリプトが動作しないため、
     * 個別でembed.jsを読み込み、global上に配置された置換処理を実行させる
     *
     * @see https://developers.facebook.com/docs/instagram/oembed
     */
    // @ts-ignore
    setTimeout(() => this.window.instgrm.Embeds.process(), 50);
  }
}
