<div fxLayout="column">
  <div
    gdAuto
    ngClass.lt-lg="w-60"
    ngClass.gt-md="w-80"
    fxFlexAlign="center"
    gdColumns="150px 1fr"
  >
    <div gdRow="1" gdColumn="1" fxLayoutAlign="center" class="icon">
      <div class="cursor-pointer" fxFlex="80px">
        <mat-icon *ngIf="!influencer?.icon_url">account_circle</mat-icon>
        <img
          *ngIf="!!influencer?.icon_url"
          [lazyLoad]="influencer?.icon_url || ''"
        />
      </div>
    </div>
    <div gdRow="1" gdColumn="2" gdAlignColumns="center">
      <div class="h2">{{ influencer?.username }}</div>
    </div>
    <div gdRow="1" gdColumn="3" fxFlexAlign="center" class="sns-icons">
      <div class="title">連携済みSNS</div>
      <app-instagram-logo *ngIf="influencer?.instagram_account" class="size-40" [enable]="true"></app-instagram-logo>
      <app-tiktok-logo *ngIf="influencer?.tiktok_account" class="size-40" [enable]="true"></app-tiktok-logo>
    </div>
  </div>

  <div
    gdAuto
    gdColumns="repeat(6, 150px)"
    gdRows="80px auto"
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="1"
      gdColumn="1"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        性別
      </div>
      <div fxFlex="calc(100% - 1.5rem)" fxLayoutAlign="center center">
        {{ (influencer?.sex | sex) || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="1"
      gdColumn="2"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        年齢
      </div>
      <div fxFlex="calc(100% - 1.5rem)" fxLayoutAlign="center center">
        {{ (influencer?.birthday | age) || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="1"
      gdColumn="3"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        既婚 or 未婚
      </div>
      <div fxFlex="calc(100% - 1.5rem)" fxLayoutAlign="center center">
        {{ (influencer?.married | partner) || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="1"
      gdColumn="4"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        子供の有無
      </div>
      <div fxFlex="calc(100% - 1.5rem)" fxLayoutAlign="center center">
        {{ (influencer?.with_children | withChildren) || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="1"
      gdColumn="5"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        エリア
      </div>
      <div fxFlex="calc(100% - 1.5rem)" fxLayoutAlign="center center">
        {{ (influencer?.prefecture | prefecture) || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="1"
      gdColumn="6"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        事務所所属
      </div>
      <div fxFlex="calc(100% - 1.5rem)" fxLayoutAlign="center center">
        {{ (influencer?.belongs_agency | agency) || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1 job-title"
      gdRow="2"
      gdColumn="1/4"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        職業
      </div>
      <div
        class="p-4"
        fxFlex="calc(100% - 1.5rem)"
        fxLayoutAlign="center center"
      >
        {{ influencer?.job_title || '-' }}
      </div>
    </div>
    <div
      class="badge my-4 mx-8 mat-elevation-z1"
      gdRow="2"
      gdColumn="4/7"
      fxLayout="column"
    >
      <div
        class="label amber-A400 amber-A400-fg"
        fxFlex="1.5rem"
        fxLayoutAlign="center center"
      >
        カテゴリ
      </div>
      <div fxFlex="80%" fxLayoutAlign="center center">
        <div *ngIf="!influencer?.categories?.length">-</div>
        <div *ngIf="!!influencer?.categories?.length">
          <mat-chip-list>
            <mat-chip *ngFor="let category of influencer?.categories">{{
              category.name
            }}</mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</div>
