import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tiktok-logo',
  templateUrl: './tiktok-logo.component.html',
  styleUrls: ['./tiktok-logo.component.scss']
})
export class TiktokLogoComponent {

  @Input()
  enable = false;

  constructor() {
  }

}
