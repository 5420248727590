import { Component, Input, OnInit } from '@angular/core';
import { TiktokPost } from '@cuepid/core';

@Component({
  selector: 'app-tiktok-post-image',
  templateUrl: './tiktok-post-image.component.html',
  styleUrls: ['./tiktok-post-image.component.scss']
})
export class TiktokPostImageComponent {

  @Input() post?: TiktokPost;
}
