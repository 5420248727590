<div class="post" fxLayout="row">
  <app-tiktok-post-image class="m-8" [post]="post"></app-tiktok-post-image>
  <div class="py-8" fxLayout="column">
    <div class="user-info" fxLayout="row" fxLayoutAlign="start center">
      <span class="mr-4">
        <img class="profile" [src]="post?.owner?.profile_picture_url" />
      </span>
      <span>@{{ post?.owner?.username }}</span>
    </div>
    <div fxLayout="column">
      <perfect-scrollbar class="caption" [config]="{ wheelPropagation: false }">
        <div
          class="mr-16"
          [innerHTML]="post?.caption | escapeHtmlTag | newLine | safeHtml"
        ></div>
      </perfect-scrollbar>
      <div class="my-8">
        <span
          [matTooltip]="
            (post?.timestamp | date: 'yyyy年MM月dd日(EEE) hh:mm') || ''
          "
          matTooltipPosition="right"
          >{{ post?.timestamp | timeago }}
        </span>
      </div>
    </div>
    <mat-divider class="my-8"></mat-divider>
    <perfect-scrollbar
      class="comments my-12"
      *ngIf="!!post?.comments && !!post?.comments?.length"
      [config]="{ wheelPropagation: false }"
    >
      <div class="comment" *ngFor="let comment of post?.comments">
        <div class="balloon my-8" fxLayout="column">
          <div
            class="text-align-left"
            [innerHTML]="comment.text | escapeHtmlTag | newLine | safeHtml"
          ></div>
          <div fxLayoutAlign="end end">{{ comment.username }}</div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="comments" *ngIf="!post?.comments || !post?.comments?.length">
      <span>コメントがありません</span>
    </div>
  </div>
</div>
